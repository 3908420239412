export default {
  install(Vue) {
    Vue.prototype.$httpRequest = function (options = {}) {
      options.timeout = options.timeout || 30000;

      if (!options.headers) {
        options.headers = {};
      }

      return Vue
        .http(options)
        .catch(response => response)
        .then(response => {
          if (response.ok) {
            if (options.success) {
              if (!response.body.success) {
                Vue.$notify.error(response.body.message || "(error)");
                return null;
              }
            }

            return response;
          }

          if (response.status === 0) {
            Vue.$notify.error("Server error (1)");
          } else {
            Vue.$notify.error("Server error (2)");
          }

          return null;
        });
    };

    Vue.$httpRequest = Vue.prototype.$httpRequest;
  }
};
