'use strict';

function packDataInQuery(urlString, data) {
  const u = new URL(urlString, document.location.href);
  for (const [ key, value ] of Object.entries(data)) {
    u.searchParams.set(key, value);
  }
  return u.toString();
}

const restClient = {
  router: null,

  restClientCommon: async ({ url, method = 'post', data }) => {
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin'
    };

    let finalUrl = url;

    if (data) {
      if (method.toLowerCase() == 'get') {
        finalUrl = packDataInQuery(url, data);
      } else {
        options.body = JSON.stringify(data);
      }
    }

    try {
      const response = await window.fetch(finalUrl, options);

      if (response?.status == 401) {
        restClient.router.push({name: 'Login'});
        return {};
      }

      const json = await response.json();
      return json;
    } catch (e) {
      console.log('restClient error: ', e);
    }

    return {};
  },

  get: args => {
    if (typeof args == 'string') {
      return restClient.restClientCommon({
        method: 'get',
        url: args
      });
    }

    return restClient.restClientCommon({ method: 'get', ...args });
  },

  put: args => restClient.restClientCommon({ method: 'put', ...args }),
  patch: args => restClient.restClientCommon({ method: 'patch', ...args }),
  // delete: args => restClient.restClientCommon({ method: 'delete', ...args }),
  post: args => restClient.restClientCommon({ method: 'post', ...args }),
};

export default {
  install(Vue, router) {
    restClient.router = router;
    Vue.prototype.$restClient = restClient;
    Vue.$restClient = Vue.prototype.$restClient;
  }
};